import { useEffect, useState, useMemo, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import * as dayjs from 'dayjs';
import { notification } from 'antd';
import { useNavigate } from "react-router-dom";

import ClaimDetail from "./ClaimDetail";
import Loading from '../components/Loading';
import { LoginContext } from '../App';

export const path = '/claim/:id';

const ClaimDetailEntry = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const { userEmail, userRole } = useContext(LoginContext);

  const [claimData, setClaimData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [formLoading, setFormLoading] = useState(false);

  async function uploadMedia(file) {
    const { data: upload } = await axios.post('/api/upload/request', { filename: file.name });
    const config = {
      'Content-Type': 'application/x-www-form-urlencoded'
    };
    await axios.put(upload.url, file, config)
    const mediaId = await axios.post('/api/upload/confirm', { key: upload.key }).then(res => res.data.id);

    return mediaId;
  }

  const {
    claim,
    documents,
    history,
    eligible_benefits,
    saved_benefits,
    saved_diagnoses,
    claimApprovalStatus,
    pv_issuing_state,
    pv_approval_state
  } = useMemo(() => {
    if (!claimData) return {};

    function claimMapper(i) {
      return {
        id: i.id,
        status: i.status,
        assignee: i.assignee,
        submitter_email: i.submitter_email,
        policy_number: i.policy_number,
        policy_holder_name: i.policy_holder_name,
        insuree_id: i.insured_id,
        insuree_name: i.insured_name,
        insuree_contact: i.phone,
        insuree_bank_name: i.bank_name,
        insuree_bank_branch: i.bank_branch,
        insuree_bank_account: i.bank_account_number,
        insuree_bank_account_name: i.bank_account_name,
        insuree_email: i.email,
        type: i.type,
        treatment_date: `${dayjs(i.treatment_start).format('DD/MM/YYYY')} - ${dayjs(i.treatment_end).format('DD/MM/YYYY')}`,
        treatment_start: i.treatment_start,
        treatment_end: i.treatment_end,
        claimed_amount_formatted: (parseFloat(i.claimed_amount)).toLocaleString('th-TH', { style: 'currency', currency: 'THB' }),
        claimed_amount: i.claimed_amount,
        approved_amount: parseFloat(i.approved_amount).toLocaleString('th-TH', { style: 'currency', currency: 'THB' }),
        reject_reason: i.reject_reason,
        remarks: i.remarks,
        source: i.source,
        gisweb_id: i.gisweb_id,
        approver_email: i.approver_email,
        submitted_date: dayjs(i.created_at).format('DD/MM/YYYY'),
        reference_number: i.reference_number,
        tpa_claim_number: i.tpa_claim_number,
        channel: i.channel,
        reject_request: i.reject_request,
      };
    }

    function documentsMapper(i) {
      return {
        url: i.url,
        title: i.type
      };
    }

    const claim = claimMapper(claimData);
    const documents = claimData.medias.map(documentsMapper);
    const history = [];
    const eligible_benefits = [];
    const saved_benefits = [];
    const saved_diagnoses = [];

    let claimApprovalStatus;
    let pv_issuing_state;
    let pv_approval_state;

    return {
      claim,
      documents,
      history,
      eligible_benefits,
      saved_benefits,
      saved_diagnoses,
      claimApprovalStatus,
      pv_issuing_state,
      pv_approval_state,
    };
  }, [claimData]);

  const editBtnVisible = userEmail === claim?.submitter_email;

  async function proceedToNextStep() {
    return await axios.post('/api/claim/' + params.id + '/proceed');
  }

  async function updateClaimHandler(data) {
    setFormLoading(true);
    await axios.patch('/api/claim/' + params.id, data);
    await new Promise(res => setTimeout(res, 300));
    setFormLoading(false);

    await fetchData();
  }

  async function resendTPAHandler() {
    setIsLoading(true);
    try {
      const { data } = await axios.post('/api/claim/' + params.id + '/resend-suncare')
      if (data.status = 'Success') {
        notification['success']({
          message: 'Success',
          description: 'Success'
        })
        navigate('/')
      }
    } catch (err) {
      notification['error']({
        message: 'Error',
        description: err?.response?.data?.error || err.message
      });
    }
    setIsLoading(false);
  }

  async function navigateToBankEdit() {
    navigate(`/claim/${params.id}/edit-bank`)
  }

  async function confirmRejectHandler(confirmedRejectReason, rejectFile) {
    setIsLoading(true);
    try {
      const rejectMediaId = await uploadMedia(rejectFile.file.originFileObj);
      await axios.patch('/api/reject-request/' + claim?.reject_request?.id, {
        confirmedRejectReason,
        rejectMediaId,
      });
      navigate('/');
    } catch (err) {
      notification['error']({
        message: 'Error',
        description: err?.response?.data?.error || err.message
      });
    }
    setIsLoading(false);
  }

  async function downloadRejectLetterHandler() {
    setIsLoading(true);

    const { data } = await axios.get(`/api/reject-request/${claim?.reject_request?.id}/reject-letter`);
    const link = document.createElement('a');
  
    // Set the href to the data URL and the download attribute to the desired file name
    link.href = data.dataUrl;
    link.download = `${claim.tpa_claim_number}-Reject_letter.pdf`;

    // Append the link to the body (necessary for Firefox)
    document.body.appendChild(link);

    // Simulate a click on the link to trigger the download
    link.click();

    // Clean up and remove the link element
    document.body.removeChild(link);
    setIsLoading(false);
  }

  async function claimSubmitHandler(status, data) {
    if (status === 'reject') {

      return;
    }

    setFormLoading(true);

    // code here
    const approved_benefits = data.benefits.map((i) => {
      return {
        code: i.code,
        description: i.description,
        incurred_amount: i.incurred_amount,
        approved_amount: i.approved_amount,
        days_amitted: i.days
      }
    });
    const diagnoses = data.diagnoses.map((i) => {
      return {
        code: i.code,
        name: i.name
      }
    });
    const _data = { approved_benefits, diagnoses };
    await axios.patch('/api/claim/' + params.id, _data);

    const claimData = await proceedToNextStep().then(res => res.data);
    await new Promise(res => setTimeout(res, 300));
    setClaimData(claimData);
    setFormLoading(false);
  }

  async function proceedAndSetData() {
    setFormLoading(true);
    const claimData = await proceedToNextStep().then(res => res.data);
    await new Promise(res => setTimeout(res, 300));
    setClaimData(claimData);
    setFormLoading(false);
  }

  async function fetchData() {
    try {
      setIsLoading(true);
      const claimData = await axios.get('/api/claim/' + params.id).then(res => res.data);
      await new Promise(res => setTimeout(res, 300));
      setClaimData(claimData);
      setIsLoading(false);
    } catch(err) {
      console.error(err);
    }
  }

  // fetching when needed
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading></Loading>
      ) : (
        <ClaimDetail
          claim={claim}
          documents={documents}
          history={history}
          eligible_benefits={eligible_benefits}
          saved_benefits={saved_benefits}
          saved_diagnoses={saved_diagnoses}
          claimApprovalStatus={claimApprovalStatus}
          pv_issuing_state={pv_issuing_state}
          pv_approval_state={pv_approval_state}
          claimSubmitHandler={claimSubmitHandler}
          updateClaimHandler={updateClaimHandler}
          resendTPAHandler={resendTPAHandler}
          pv_issuing_handler={proceedAndSetData}
          pv_approval_handler={proceedAndSetData}
          editBankHandler={navigateToBankEdit}
          editBtnVisible={editBtnVisible}
          confirmRejectHandler={confirmRejectHandler}
          downloadRejectLetterHandler={downloadRejectLetterHandler}
          userRole={userRole}
        ></ClaimDetail>
      )}
      {formLoading && <Loading/>}
    </>
  );
}

export default ClaimDetailEntry;